// form-with-trix-controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit']

  disableSubmitIfTrixAttachmentsUploading(/*event*/) {
    const { hasTrixAttachmentsUploading } = this

    this.submitTargets.forEach(
      (submitTarget) => (submitTarget.disabled = hasTrixAttachmentsUploading)
    )
  }

  get hasTrixAttachmentsUploading() {
    return this.trixAttachments.some((attachment) => attachment.isPending())
  }

  get trixAttachments() {
    return this.trixElements.flatMap((trix) =>
      trix.editor.getDocument().getAttachments()
    )
  }

  get trixElements() {
    return Array.from(this.element.querySelectorAll('trix-editor'))
  }
}
