document.addEventListener('turbolinks:load', (event) => {
  window.addEventListener('trix-file-accept', function (event) {
    const acceptedTypes = ['image/jpeg', 'image/png']
    if (!acceptedTypes.includes(event.file.type)) {
      event.preventDefault()
      alert('No se aceptan archivos que no sean jpeg o png')
    }

    const maxFileSize = 2 * 1024 * 1024 // 2MB
    if (event.file.size > maxFileSize) {
      event.preventDefault()
      alert('Solo se pueden adjuntar imágenes de máximo 2MB')
    }
  })
})
