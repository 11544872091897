import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  select(event) {
    const selected = event.target.value
    this.elementTargets.forEach((el) => {
      if (selected === el.dataset.selectorId) {
        el.classList.remove('hidden')
        el.querySelectorAll('input').forEach((v) => (v.disabled = false))
      } else {
        el.classList.add('hidden')
        el.querySelectorAll('input').forEach((v) => (v.disabled = true))
      }
    })
  }
}
