//import Rails from 'rails-ujs'
import { Controller } from 'stimulus'
const axios = require('axios')

export default class extends Controller {
  static targets = ['region', 'province', 'ubigeo']

  connect() {}

  region(event) {
    const obj = this
    const regionCode = event.target.value
    axios
      .get(`/admin/ubigeos.json?region_code=${regionCode}`)
      .then(function (response) {
        obj.provinceTarget.innerHTML = '<option value="">Provincia</option>'
        obj.ubigeoTarget.innerHTML = '<option value="">Distrito</option>'
        obj.refreshDropdownValues(response.data, obj.provinceTarget)
      })
  }

  province(event) {
    const obj = this
    const provinceCode = event.target.value

    axios
      .get(`/admin/ubigeos.json?province_code=${provinceCode}`)
      .then(function (response) {
        obj.ubigeoTarget.innerHTML = '<option value="">Distrito</option>'
        obj.refreshDropdownValues(response.data, obj.ubigeoTarget)
      })
  }

  refreshDropdownValues(data, target) {
    data.forEach(
      (value) =>
        (target.innerHTML += `<option value="${value.id}">${value.name} </option>`)
    )
  }
}
