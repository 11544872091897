import Flatpickr from 'stimulus-flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import 'flatpickr/dist/themes/light.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static targets = ['clearButton']

  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Spanish,
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    document.querySelector('.js-clear-flatpickr').classList.remove('hidden')
  }

  clear() {
    this.fp.clear()
  }
}
