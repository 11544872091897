import Choices from 'choices.js'
import pDebounce from 'p-debounce'
import qs from 'query-string'

const generateChoices = (object, el) => {
  object.url = el.dataset.url
  const searchQuery = el.dataset.search_query || 'search'
  const choices = el.dataset.choices ? JSON.parse(el.dataset.choices) : []
  const containerInner =
    el.dataset.class_name == 'branch-offices'
      ? 'branch-offices choices__inner__custom'
      : 'choices__inner__custom'

  object.choicesDocuments = new Choices(el, {
    removeItemButton: true,
    noChoicesText: 'No hay opciones para elegir',
    noResultsText: 'No se han encontrado resultados',
    itemSelectText: 'Seleccionar',
    placeholder: true,
    placeholderValue: 'Búsqueda de resolución',
    searchResultLimit: 50,
    choices,
    classNames: {
      containerInner: containerInner,
      input: 'choices__input__custom',
      placeholder: 'opacity-100',
    },
  })

  const handleSearch = async (event) => {
    try {
      if (!object.url) return
      const params = qs.parse(object.url?.split('?')?.[1])
      const stringParams = qs.stringify({
        ...params,
        search: event.detail.value,
      })
      object.url = `${object.url?.split('?')[0]}?${stringParams}`
      const response = await fetch(object.url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      const items = await response.json()
      const options = items.map((item) => {
        return { value: item.id, label: item.label }
      })
      object.choicesDocuments.setChoices(options, 'value', 'label', true)
    } catch (err) {
      console.error(err)
    }
  }

  const debounced = pDebounce(handleSearch, 500)

  if (object.url != null) {
    el.addEventListener(
      'search',
      (event) => {
        debounced(event, object.choicesDocuments)
      },
      false
    )
  }

  return object
}

export { generateChoices }
