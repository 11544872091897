import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  clear() {
    this.flatpickrController.fp.clear()
    document.querySelector('.js-clear-flatpickr').classList.add('hidden')
  }

  get flatpickrController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element.querySelector('[identifier]'),
      'flatpickr'
    )
  }
}
